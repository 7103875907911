<template>
  <v-container
    fluid
    tag="section">
    <v-row dense>
      <v-col md="4">
        <v-card
          :loading="loading"
          max-width="374">
          <v-img
            max-height="100%"
            :src="require(`@/assets/menu-images/${cards[0].imgPath}`)"
            class="hover-img"></v-img>
          <v-row>
            <v-card-title style="min-height: 100px">
              <v-icon left small>mdi-pencil-outline</v-icon>
              {{$t(cards[0].cardNo)}}
            </v-card-title>
            <v-img
              class="logo-img"
              :src="require(`@/assets/menu-images/${cards[0].logoPath}`)"></v-img>
          </v-row>
          <v-card-text class="subtitle-1">
            <v-icon left small>mdi-pencil-outline</v-icon>
            {{$t(cards[0].cardHolderName)}}
          </v-card-text>
          <v-card-text class="subtitle-1">
            <v-icon left small>mdi-pencil-outline</v-icon>
            {{$t(cards[0].bankName)}}
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn class="action-button" color="primary" block @click="$router.push({ name: cards[0].link })">
              {{$t(cards[0].title)}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "PaymentDetails",
    data: () => ({
      loading: false,
      cards: [
        {
          title: 'Copy card info',
          cardNo: '1234 4567 8910 0069',
          imgPath: 'platinum-visa.png',
          logoPath: 'mc-logo.png',
          flex: 6,
          link: 'Payment history',
          cardType: 'Mastercard',
          bankName: 'Privatbank',
          cardHolderName: 'Andrii Kharyshyn'
        }
      ]
    }),
  }
</script>

<style>
  .hover-img {
    border-radius: 5px 5px;
    justify-content: center;
  }

  .logo-img {
    justify-content: center;
    max-height: 10%;
  }
</style>
